export interface Unit {
  id: string;
  name: string;
  Rating: string;
  SystemVoltage: string;
  SolarInVoltage: string;
  MPPTRating: string;
  price: number;
  datasheet: string;
}

export const inverters = [
  {
    imgSrc: "../goldenmax.jpeg",
    id: "goldenmax2",
    name: "Goldenmax 2kVA/24V",
    Rating: "2000W",
    SystemVoltage: "24V",
    SolarInVoltage: "150VDC",
    MPPTRating: "60A",
    price: 37500,
    datasheet: "Not available",
  },
  {
    imgSrc: "../goldenmax.jpeg",
    id: "goldenmax3",
    name: "Goldenmax 3kVA/24V",
    Rating: "3000W",
    SystemVoltage: "24V",
    SolarInVoltage: "150VDC",
    MPPTRating: "60A",
    price: 47000,
    datasheet: "Not available",
  },
  {
    imgSrc: "../goldenmax.jpeg",
    id: "goldenmax5",
    name: "Goldenmax 5kVA/48V",
    Rating: "5000W",
    SystemVoltage: "48V",
    SolarInVoltage: "250VDC",
    MPPTRating: "120A",
    price: 68000,
    datasheet: "Not available",
  },
  {
    imgSrc: "../must.jpg",
    id: "must15",
    name: "Must 1.5kVA/12V",
    Rating: "1500W",
    SystemVoltage: "12V",
    SolarInVoltage: "145VDC",
    MPPTRating: "60A",
    price: 26000,
    datasheet:
      "https://www.mustpower.com/product/pv1800-vpm-%e2%85%b1-series-1-4kw/",
  },
  {
    imgSrc: "../must.jpg",
    id: "must22",
    name: "Must 2.2kVA/24V",
    Rating: "2200W",
    SystemVoltage: "24V",
    SolarInVoltage: "160VDC",
    MPPTRating: "60A",
    price: 26000,
    datasheet:
      "https://www.mustpower.com/product/pv1800-vpm-%e2%85%b1-series-1-4kw/",
  },
  {
    imgSrc: "../must.jpg",
    id: "must32",
    name: "Must 3.2kVA/24V",
    Rating: "3200W",
    SystemVoltage: "24V",
    SolarInVoltage: "160VDC",
    MPPTRating: "60A",
    price: 37500,
    datasheet:
      "https://www.mustpower.com/product/pv1800-vpm-%e2%85%b1-series-1-4kw/",
  },
  {
    imgSrc: "../must.jpg",
    id: "must35",
    name: "Must 3.5kVA/24V",
    Rating: "3500W",
    SystemVoltage: "24V",
    SolarInVoltage: "160VDC",
    MPPTRating: "60A",
    price: 45000,
    datasheet:
      "https://www.mustpower.com/product/pv1800-vpm-%e2%85%b1-series-1-4kw/",
  },
  {
    imgSrc: "../must35.jpg",
    id: "must55",
    name: "Must 5.5kVA/48V",
    Rating: "5500W",
    SystemVoltage: "48V",
    SolarInVoltage: "450VDC",
    MPPTRating: "28A",
    price: 64000,
    datasheet:
      "https://www.mustpower.com/product/pv1800-prem-series-1-6-5-5kw/",
  },
  {
    imgSrc: "../must6.jpg",
    id: "must6",
    name: "Must 6kVA/48V",
    Rating: "6000W",
    SystemVoltage: "48V",
    SolarInVoltage: "500VDC",
    MPPTRating: "120A",
    price: 96500,
    datasheet: "https://www.mustpower.com/product/pv1900-exp-series-4-6kw/",
  },
  {
    imgSrc: "../must10.jpg",
    id: "must10",
    name: "Must 10kVA/48V",
    Rating: "10000W",
    SystemVoltage: "48V",
    SolarInVoltage: "500VDC",
    MPPTRating: "150A",
    price: 178500,
    datasheet: "https://www.mustpower.com/product/pv1900m-exp-series-6-2-12kw/",
  },
  {
    imgSrc: "../mecer.jpeg",
    id: "mecer5",
    name: "Mecer Axpert 5kVA/48V",
    Rating: "5000W",
    SystemVoltage: "48V",
    SolarInVoltage: "105VDC",
    MPPTRating: "50A",
    price: 84000,
    datasheet: "https://www.mecerpc.co.za/pdf_downloads/20/206733_PDF1.pdf",
  },
  {
    imgSrc: "../felicity5.jpg",
    id: "felicity5",
    name: "Felicity 5kVA/48V",
    Rating: "5000W",
    SystemVoltage: "48V",
    SolarInVoltage: "480VDC",
    MPPTRating: "50A",
    price: 157500,
    datasheet:
      "https://www.felicitysolar.com/u_file/2311/17/file/IVPS-IVPMSeriesUserGuide.pdf",
  },
  {
    imgSrc: "../felicity10.jpg",
    id: "felicity10",
    name: "Felicity 10kVA/48V",
    Rating: "10000W",
    SystemVoltage: "48V",
    SolarInVoltage: "195VDC",
    MPPTRating: "120A",
    price: 194000,
    datasheet: "https://www.felicitysolar.com/u_file/2311/17/file/IVPM.pdf",
  },
  {
    imgSrc: "../deye30.png",
    id: "deye30",
    name: "Deye 30kVA/48V",
    Rating: "30000W",
    SystemVoltage: "48V",
    SolarInVoltage: "800VDC",
    MPPTRating: "120A",
    price: 336000,
    datasheet:
      "https://www.deyeinverter.com/product/three-phase-lv/three-phase-lv-sun20-25-3035kg02lv.html",
  },
  {
    imgSrc: "../deye30.png",
    id: "deye25",
    name: "Deye 25kVA/48V",
    Rating: "25000W",
    SystemVoltage: "48V",
    SolarInVoltage: "800VDC",
    MPPTRating: "120A",
    price: 283500,
    datasheet:
      "https://www.deyeinverter.com/product/three-phase-lv/three-phase-lv-sun20-25-3035kg02lv.html",
  },
  {
    imgSrc: "../sevenss55.png",
    id: "sevenss5.5",
    name: "Seven SS Stars 5.5kVA/48V",
    Rating: "5500W",
    SystemVoltage: "48V",
    SolarInVoltage: "n/a",
    MPPTRating: "n/a",
    price: 68500,
    datasheet: "n/a",
  },
  {
    imgSrc: "../sevenss55.png",
    id: "sevenss3.5",
    name: "Seven SS Stars 3.5kVA/24V",
    Rating: "3500W",
    SystemVoltage: "24V",
    SolarInVoltage: "n/a",
    MPPTRating: "n/a",
    price: 61500,
    datasheet: "n/a",
  },
  {
    imgSrc: "../sevenss55.png",
    id: "sevenss1.5",
    name: "Seven SS Stars 1.5kVA/12V",
    Rating: "1500W",
    SystemVoltage: "12V",
    SolarInVoltage: "n/a",
    MPPTRating: "n/a",
    price: 38000,
    datasheet: "n/a",
  },
  {
    imgSrc: "../taico.png",
    id: "taico3.6",
    name: "Taico 3.6kVA/24V",
    Rating: "3600W",
    SystemVoltage: "24V",
    SolarInVoltage: "450V",
    MPPTRating: "27A",
    price: 72000,
    datasheet:
      "https://cdn.enfsolar.com/z/pp/2023/5/9pv5sgx4c2wf6vx/TAICO-Hybrid-Inverter-6KW.pdf",
  },
  {
    imgSrc: "../luminous125.png",
    id: "luminous12.5",
    name: "Luminous 12.5kVA/48V",
    Rating: "12500W",
    SystemVoltage: "48V",
    SolarInVoltage: "300V",
    MPPTRating: "44A",
    price: 315000,
    datasheet: "n/a",
  },
  {
    imgSrc: "../luminous125.png",
    id: "luminous9.5",
    name: "Luminous 9.5kVA/48V",
    Rating: "9500W",
    SystemVoltage: "48V",
    SolarInVoltage: "300V",
    MPPTRating: "33A",
    price: 262500,
    datasheet: "n/a",
  },
  {
    imgSrc: "../luminous4.png",
    id: "luminous4",
    name: "Luminous 4kVA/48V",
    Rating: "4000W",
    SystemVoltage: "48V",
    SolarInVoltage: "600V",
    MPPTRating: "22A",
    price: 52000,
    datasheet:
      "https://5.imimg.com/data5/SELLER/Doc/2020/10/WS/ED/FS/8721586/luminous-nxi-140.pdf",
  },
  {
    imgSrc: "../luminous3.png",
    id: "luminous3.75",
    name: "Luminous 3.75kVA/48V",
    Rating: "3750W",
    SystemVoltage: "48V",
    SolarInVoltage: "165V",
    MPPTRating: "n/a",
    price: 97500,
    datasheet: "n/a",
  },
  {
    imgSrc: "../luminous4.png",
    id: "luminous3",
    name: "Luminous 3kVA/36V",
    Rating: "3600W",
    SystemVoltage: "36V",
    SolarInVoltage: "600V",
    MPPTRating: "11A",
    price: 69000,
    datasheet:
      "https://5.imimg.com/data5/SELLER/Doc/2020/10/WS/ED/FS/8721586/luminous-nxi-140.pdf",
  },
  {
    imgSrc: "../luminous4.png",
    id: "luminous2",
    name: "Luminous 2kVA/24V",
    Rating: "2000W",
    SystemVoltage: "24V",
    SolarInVoltage: "600V",
    MPPTRating: "11A",
    price: 63000,
    datasheet:
      "https://5.imimg.com/data5/SELLER/Doc/2020/10/WS/ED/FS/8721586/luminous-nxi-140.pdf",
  },
  {
    imgSrc: "../hinen6.jpeg",
    id: "hinen6",
    name: "Hinen 6kVA/48V",
    Rating: "6000W",
    SystemVoltage: "48V",
    SolarInVoltage: "530V",
    MPPTRating: "32A",
    price: 79500,
    datasheet:
      "https://cdn.shopify.com/s/files/1/0737/0063/8995/files/Hinen_N6000s_Datasheet_AF.pdf?v=1727079874",
  },
  {
    imgSrc: "../hinen12.PNG",
    id: "hinen12",
    name: "Hinen 12kVA/48V",
    Rating: "12000W",
    SystemVoltage: "48V",
    SolarInVoltage: "1000V",
    MPPTRating: "56A",
    price: 239000,
    datasheet:
      "https://cdn.shopify.com/s/files/1/0737/0063/8995/files/Hinen_H12000t_Datasheet_AF.pdf?v=1727079874",
  },
  {
    imgSrc: "../deye16.png",
    id: "deye16",
    name: "Deye 16kVA/48V",
    Rating: "16000W",
    SystemVoltage: "48V",
    SolarInVoltage: "500V",
    MPPTRating: "78A",
    price: 504000,
    datasheet:
      "https://www.deyeinverter.com/deyeinverter/2022/06/05/%E3%80%90b%E3%80%91%E6%9C%8D%E5%8A%A1%E6%8C%87%E5%8D%97%E5%82%A8%E8%83%BD%E5%8D%95%E7%9B%B8-sun-(12-16)k-sg01lp1-eudeye%E5%BD%A9%E5%8D%B0157g%E9%93%9C%E7%89%88%E7%BA%B8%E8%8B%B1%E6%96%87%E5%8D%95%E9%A1%B5.pdf",
  },
  {
    imgSrc: "../deye16.png",
    id: "deye8",
    name: "Deye 8kVA/48V",
    Rating: "8000W",
    SystemVoltage: "48V",
    SolarInVoltage: "500V",
    MPPTRating: "44A",
    price: 330500,
    datasheet:
      "https://www.deyeinverter.com/deyeinverter/2021/07/12/%E3%80%90b%E3%80%91hybridinvertersun-8k-sg01lp1-eu-4.pdf",
  },
  {
    imgSrc: "../deye16.png",
    id: "deye5",
    name: "Deye 5kVA/48V",
    Rating: "5000W",
    SystemVoltage: "48V",
    SolarInVoltage: "500V",
    MPPTRating: "26A",
    price: 161500,
    datasheet:
      "https://www.deyeinverter.com/deyeinverter/2021/04/14/%E3%80%90b%E3%80%91hybridinvertersun-3.6-5k-sg0103lp1-eu.pdf",
  },
  {
    imgSrc: "../deye16.png",
    id: "deye3.6",
    name: "Deye 3.6kVA/48V",
    Rating: "3600W",
    SystemVoltage: "48V",
    SolarInVoltage: "500V",
    MPPTRating: "26A",
    price: 159500,
    datasheet:
      "https://www.deyeinverter.com/deyeinverter/2021/04/14/%E3%80%90b%E3%80%91hybridinvertersun-3.6-5k-sg0103lp1-eu.pdf",
  },
  {
    imgSrc: "../jinko10.png",
    id: "jinko10",
    name: "Jinko 10kVA/96V",
    Rating: "10000W",
    SystemVoltage: "96V",
    SolarInVoltage: "1000V",
    MPPTRating: "25A",
    price: 320000,
    datasheet: "https://www.jinkosolar.com/themes/basices/skin/pdf/4.pdf",
  },
  {
    imgSrc: "../jinko10.png",
    id: "jinko8",
    name: "Jinko 8kVA/48V",
    Rating: "8000W",
    SystemVoltage: "48V",
    SolarInVoltage: "500V",
    MPPTRating: "22A",
    price: 294000,
    datasheet: "https://www.jinkosolar.com/themes/basices/skin/pdf/5.pdf",
  },
  {
    imgSrc: "../jinko10.png",
    id: "jinko3.6",
    name: "Jinko 3.6kVA/48V",
    Rating: "3600W",
    SystemVoltage: "48V",
    SolarInVoltage: "500V",
    MPPTRating: "12.5A",
    price: 140000,
    datasheet: "https://www.jinkosolar.com/themes/basices/skin/pdf/4.pdf",
  },
  {
    imgSrc: "../jinko10.png",
    id: "jinko5",
    name: "Jinko 5kVA/48V",
    Rating: "3600W",
    SystemVoltage: "48V",
    SolarInVoltage: "500V",
    MPPTRating: "12.5A",
    price: 187000,
    datasheet: "https://www.jinkosolar.com/themes/basices/skin/pdf/4.pdf",
  },
  {
    imgSrc: "../growatt-es.jpg",
    id: "growatt-es5",
    name: "Growatt SPF5000ES",
    Rating: "5000W",
    SystemVoltage: "48V",
    SolarInVoltage: "450V",
    MPPTRating: "100A",
    price: 104000,
    datasheet:
      "https://www.allview.ro/green/cms/atasamente/pdf/115/fisa-tehnica-invertor-growatt-spf-5000es.pdf",
  },
  {
    imgSrc: "../growatt-es.jpg",
    id: "growatt-es3.5",
    name: "Growatt SPF3500ES",
    Rating: "3500W",
    SystemVoltage: "48V",
    SolarInVoltage: "450V",
    MPPTRating: "80A",
    price: 73500,
    datasheet:
      "https://www.allview.ro/green/cms/atasamente/pdf/115/fisa-tehnica-invertor-growatt-spf-5000es.pdf",
  },
  {
    imgSrc: "../growatt-tl.png",
    id: "growatt-tl3",
    name: "Growatt MIN3000TL-X",
    Rating: "3000W",
    SystemVoltage: "48V",
    SolarInVoltage: "500V",
    MPPTRating: "25A",
    price: 57500,
    datasheet:
      "https://www.ausgreensolar.com.au/wp-content/uploads/2020/05/Growatt-Inverter-Datasheet-.pdf",
  },
];
