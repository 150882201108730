export interface Unit {
  id: string;
  name: string;
  Capacity: string;
  Voltage: string;
  Type: string;
  price: number;
  datasheet: string;
}

export const batteries = [
  {
    imgSrc: "../enertec5-48.jpg",
    id: "enertec5-48",
    name: "Enertec Megatank 5kWh/48V",
    Capacity: "100Ah",
    Voltage: "48V",
    Type: "Lithium Ion",
    price: 162500,
    datasheet:
      "https://enertec.co.za/couch/uploads/file/spec-sheets/gl-series-specifications-enertec.pdf",
  },
  {
    imgSrc: "../jinko5-48.jpg",
    id: "jinko5-48",
    name: "Jinko stackable 5kWh/48V",
    Capacity: "100Ah",
    Voltage: "48V",
    Type: "Lithium Ion",
    price: 252000,
    datasheet:
      "https://proteces.com/wp-content/uploads/2023/03/JKS-B51100-CI-A4.pdf",
  },
  {
    imgSrc: "../felicity5-48.jpg",
    id: "felicity5-48",
    name: "Felicity wall-mount 5kWh/48V",
    Capacity: "100Ah",
    Voltage: "48V",
    Type: "Lithium Ion",
    price: 196000,
    datasheet:
      "https://www.felicitysolar.com/u_file/2311/15/file/LPBF48200-H.pdf",
  },
  {
    imgSrc: "../growatt-axe.jpg",
    id: "growattaxe5-48",
    name: "Growatt Axe 5kWh/48V",
    Capacity: "100Ah",
    Voltage: "48V",
    Type: "Lithium Ion",
    price: 210000,
    datasheet:
      "https://en.growatt.com/upload/file/AXE_5.0L_Modular_Battery_Datasheet_EN_202401.pdf",
  },
  {
    imgSrc: "../weco5-48.png",
    id: "weco5-48",
    name: "WeCo 5.3kWh/48V",
    Capacity: "100Ah",
    Voltage: "48V",
    Type: "Lithium Ion",
    price: 294000,
    datasheet:
      "https://www.ffsolar.com/products/pdf/5K3_LV_HV_datasheet_v.1G.pdf",
  },
  {
    imgSrc: "../vestwoods5--48.jpg",
    id: "vestwoods5-48",
    name: "Vestwoods 5kWh/48V",
    Capacity: "100Ah",
    Voltage: "48V",
    Type: "Lithium Ion",
    price: 143500,
    datasheet: "https://www.vestwoods.com/documents/VE51100RS.pdf",
  },
  {
    imgSrc: "../must5-48.jpeg",
    id: "must5-48",
    name: "Must 5kWh/48V/24V",
    Capacity: "100Ah",
    Voltage: "48V/24V",
    Type: "Lithium Ion",
    price: 147000,
    datasheet:
      "https://saftronics.co.za/wp-content/uploads/2023/08/MUST-5kW-10kW.pdf",
  },
  {
    imgSrc: "../tbb5-48.png",
    id: "tbb5-48",
    name: "TBB 5kWh/48V",
    Capacity: "100Ah",
    Voltage: "48V",
    Type: "Lithium Ion",
    price: 168000,
    datasheet:
      "https://image.indotrading.com/co52208/pdf/p1025357/es100%E5%BD%A9%E5%86%8C.pdf",
  },
  {
    imgSrc: "../byd5-48.png",
    id: "byd5-48",
    name: "BYD 5kWh/48V",
    Capacity: "100Ah",
    Voltage: "48V",
    Type: "Lithium Ion",
    price: 181500,
    datasheet:
      "https://www.bydbatterybox.com/uploads/downloads/BYD_Battery-Box_LV_Flex_Datasheet_V1.4-62b3f4c515a23.pdf",
  },
  {
    imgSrc: "../alltop5-48.jpg",
    id: "alltop5-48",
    name: "Alltop 5kWh/48V",
    Capacity: "100Ah",
    Voltage: "48V",
    Type: "Lithium Ion",
    price: 1294000,
    datasheet: "Not available",
  },
  {
    imgSrc: "../eastman150-12.png",
    id: "eastman150-12",
    name: "Eastman Tubular 150Ah/12V",
    Capacity: "150Ah",
    Voltage: "12V",
    Type: "Flooded Lead acid",
    price: 42000,
    datasheet:
      "https://awdisolar.com/wp-content/uploads/2023/07/EASTMAN-TTC-EM100-300Ah-22MAR23-1.pdf",
  },
  {
    imgSrc: "../eastman200-12fld.png",
    id: "eastman200-12fld",
    name: "Eastman Tubular 200Ah/12V",
    Capacity: "200Ah",
    Voltage: "12V",
    Type: "Flooded Lead acid",
    price: 48000,
    datasheet:
      "https://awdisolar.com/wp-content/uploads/2023/07/EASTMAN-TTC-EM100-300Ah-22MAR23-1.pdf",
  },
  {
    imgSrc: "../eastman300-12.png",
    id: "eastman300-12fld",
    name: "Eastman Tubular 300Ah/12V",
    Capacity: "300Ah",
    Voltage: "12V",
    Type: "Flooded Lead acid",
    price: 55500,
    datasheet:
      "https://awdisolar.com/wp-content/uploads/2023/07/EASTMAN-TTC-EM100-300Ah-22MAR23-1.pdf",
  },
  {
    imgSrc: "../felicity7-48.jpeg",
    id: "felicity7-48",
    name: "Felicity 7.2kWh/48V",
    Capacity: "150Ah",
    Voltage: "48V",
    Type: "Lithium ion",
    price: 273000,
    datasheet:
      "https://www.felicitysolar.com/u_file/2311/15/file/LPBFBattery.pdf",
  },
  {
    imgSrc: "../felicity10-48.jpg",
    id: "felicity10-48",
    name: "Felicity 10kWh/48V",
    Capacity: "200Ah",
    Voltage: "48V",
    Type: "Lithium ion",
    price: 357000,
    datasheet:
      "https://www.felicitysolar.com/u_file/2311/15/file/LPBA48200.pdf",
  },
  {
    imgSrc: "../felicity15-48.jpg",
    id: "felicity15-48",
    name: "Felicity 15kWh/48V",
    Capacity: "200Ah",
    Voltage: "48V",
    Type: "Lithium ion",
    price: 357000,
    datasheet: "https://fr.felicitysolar.com/u_file/2312/13/file/LPBA48300.pdf",
  },
  {
    imgSrc: "../felicity12-48.jpg",
    id: "felicity12-48",
    name: "Felicity 12.5kWh/48V",
    Capacity: "200Ah",
    Voltage: "48V",
    Type: "Lithium ion",
    price: 447000,
    datasheet:
      "https://www.felicitysolar.com/u_file/2311/16/file/LPBA48250.pdf",
  },
  {
    imgSrc: "../felicity17-48.jpeg",
    id: "felicity17-48",
    name: "Felicity 17.5kWh/48V",
    Capacity: "200Ah",
    Voltage: "48V",
    Type: "Lithium ion",
    price: 502000,
    datasheet:
      "https://www.felicitysolar.com/u_file/2311/15/file/LPBF48350LiFePO4LithiumBattery1.pdf",
  },
  {
    imgSrc: "../felicity150-12.png",
    id: "felicity150-12",
    name: "Felicity 150Ah/12V",
    Capacity: "150Ah",
    Voltage: "12V",
    Type: "Lithium ion",
    price: 52500,
    datasheet:
      "https://tracerpower.com/wp-content/uploads/2022/11/BP2815-Data-Sheet.pdf",
  },
  {
    imgSrc: "../luminous220-12.png",
    id: "luminous200-12gel",
    name: "Luminous Gel 200Ah/12V",
    Capacity: "200Ah",
    Voltage: "12V",
    Type: "Gel",
    price: 37500,
    datasheet:
      "https://augustauto.co.ke/product/luminous-200ah-12v-super-rugged-battery-lum1220020hr-12v-200ah/",
  },
  {
    imgSrc: "../felicity150-12gel.jpg",
    id: "felicity150-12gel",
    name: "Felicity Gel 150Ah/12V",
    Capacity: "150Ah",
    Voltage: "12V",
    Type: "Gel",
    price: 28000,
    datasheet: "https://www.felicitysolar.com/u_file/2311/17/file/GEL.pdf",
  },
  {
    imgSrc: "../felicity150-24.jpg",
    id: "felicity150-24",
    name: "Felicity 150Ah/24V",
    Capacity: "150Ah",
    Voltage: "24V",
    Type: "Lithium ion",
    price: 28000,
    datasheet:
      "https://www.felicitysolar.com/u_file/2311/16/file/LPBF-SSpecifications.pdf",
  },
  {
    imgSrc: "../felicity200-12.jpg",
    id: "felicity200-12",
    name: "Felicity 200Ah/12V",
    Capacity: "200Ah",
    Voltage: "12V",
    Type: "Lithium ion",
    price: 78400,
    datasheet: "Not available",
  },
  {
    imgSrc: "../felicity200-24.jpg",
    id: "felicity200-24",
    name: "Felicity 200Ah/24V",
    Capacity: "200Ah",
    Voltage: "24V",
    Type: "Lithium ion",
    price: 186000,
    datasheet:
      "https://www.felicitysolar.com/u_file/2311/16/file/LPBF-SSpecifications.pdf",
  },
  {
    imgSrc: "../huawei5-48.jpg",
    id: "huawei5-48",
    name: "Huawei Luna 5kWh/48V",
    Capacity: "5kWh/5kW",
    Voltage: "48V",
    Type: "Lithium ion",
    price: 388000,
    datasheet:
      "https://solar.huawei.com/download?p=%2F-%2Fmedia%2FSolar%2Fattachment%2Fpdf%2Feu%2Fdatasheet%2FLUNA2000-5_10_15-S0.pdf",
  },
  {
    imgSrc: "../livoltek5-48.png",
    id: "livoltek5-48",
    name: "Livoltek 5kWh/48V",
    Capacity: "100Ah",
    Voltage: "48V",
    Type: "Lithium ion",
    price: 231000,
    datasheet:
      "https://livoltek-prodlib.hxgroup.com/2-Product-Info/1-Product%20Catalogue%20%26%20Leaflet/English/Product%20Leaflets24.01/6.%20Low-Voltage%20Residential%20Battery%28Lithium%20Battery%29.pdf",
  },
  {
    imgSrc: "../luminous100-12fld.jpg",
    id: "luminous100-12",
    name: "Luminous 100Ah/12V",
    Capacity: "100Ah",
    Voltage: "12V",
    Type: "Lead acid",
    price: 17500,
    datasheet:
      "https://5.imimg.com/data5/SELLER/Doc/2022/9/OU/GR/GB/85158370/luminous-ilst12042-100ah-tubular-battery.pdf",
  },
  {
    imgSrc: "../ritar200-12.png",
    id: "ritar200-12",
    name: "Ritar 200Ah/12V",
    Capacity: "200Ah",
    Voltage: "12V",
    Type: "Gel",
    price: 47000,
    datasheet:
      "https://datasheet.octopart.com/DG12-200-Ritar-datasheet-67183632.pdf",
  },
  {
    imgSrc: "../trojan72-12.jpg",
    id: "trojan72-12",
    name: "Trojan 72Ah/12V",
    Capacity: "72Ah",
    Voltage: "12V",
    Type: "Lead acid",
    price: 18500,
    datasheet:
      "https://assets.ctfassets.net/nh2mdhlonj7m/2ndaOi7Uifv99l3uQe2XzG/3d21a8e7d294c7a01ee21e20990969bb/27TMH_Trojan_Data_Sheets.pdf",
  },
  {
    imgSrc: "../trojan170-8.png",
    id: "trojan170-8",
    name: "Trojan 170Ah/8V",
    Capacity: "170Ah",
    Voltage: "8V",
    Type: "Lead acid",
    price: 23000,
    datasheet:
      "https://assets.ctfassets.net/nh2mdhlonj7m/67yEZw5ZBDN2zoOnBHHrgt/c79f1dff7440daecbd3228eaad77fd4d/T875_Trojan_Data_Sheets.pdf",
  },
  {
    imgSrc: "../vestwoods100-12.jpg",
    id: "vestwoods100-12",
    name: "Vestwoods 100Ah/12V",
    Capacity: "100Ah",
    Voltage: "12V",
    Type: "Lithium ion",
    price: 52500,
    datasheet: "Not available",
  },
  {
    imgSrc: "../vestwoods100-12.jpg",
    id: "vestwoods100-12",
    name: "Vestwoods 100Ah/12V",
    Capacity: "100Ah",
    Voltage: "12V",
    Type: "Lithium ion",
    price: 52500,
    datasheet: "Not available",
  },
  {
    imgSrc: "../luminous100-12fld.jpg",
    id: "luminous200-12",
    name: "Luminous 200Ah/12V",
    Capacity: "200Ah",
    Voltage: "12V",
    Type: "Lead acid",
    price: 25500,
    datasheet:
      "https://awdisolar.com/wp-content/uploads/2022/03/Luminous_NRGT2200RF-200Ah-@-C20.pdf",
  },
  {
    imgSrc: "../vestwoods200-12.jpeg",
    id: "vestwoods200-12",
    name: "Vestwoods 200Ah/12V",
    Capacity: "200Ah",
    Voltage: "12V",
    Type: "Lithium ion",
    price: 25500,
    datasheet:
      "https://www.vestwoods.com/documents/VESTWOODS-PRODUCT-CATALOG-2023_compressed.pdf",
  },
  {
    imgSrc: "../pylontech2.png",
    id: "pylontech2",
    name: "Pylontech 2.4kWh/48V",
    Capacity: "50Ah",
    Voltage: "48V",
    Type: "Lithium ion",
    price: 100500,
    datasheet:
      "https://www.vpsolar.com/download/catalog/Storage/Pylontech/US2000C-Pylontech-datasheet-EN.pdf",
  },
  {
    imgSrc: "../growatt3.jpg",
    id: "growatt3",
    name: "Growatt 3.3kWh/48V",
    Capacity: "50Ah",
    Voltage: "48V",
    Type: "Lithium ion",
    price: 115500,
    datasheet:
      "https://jcsolarpanels.co.za/wp-content/uploads/2021/12/Growatt-Hope-3.3L-C1-Lithium-Battery.pdf",
  },
];
